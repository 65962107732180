<template>
  <div>
    <Modal
      modalTitle="Editar doação"
      :cardTitle="true"
      :withClose="true"
      myMaxWidth="750px"
      :modalFooter="true"
      v-model="show"
    >
      <v-container fluid pa-0>
        <div class="content-text-block overflow font-sys">
          <v-layout row wrap>
            <v-flex xs12>
              <div class="container">
                <h2 class="fw-400 color-theme-texto">Valor da doação</h2>
                <div
                  class="display-flex mt-4 item-receipt"
                  v-for="cartItem in cart.cartItems"
                  :key="cartItem.id"
                >
                  <div class="description-donation-pf">
                    <div class="c-red f-size-13">
                      {{ getInstitutionName(cartItem) }}
                    </div>
                    <div class="c-text-dark f-w-bold">
                      {{ cartItem.project.shortName }}
                    </div>
                    <div class="c-text-dark f-w-bold" v-if="cardCurrentLastDigits">Doação Recorrente</div>
                    <div class="c-text-dark f-w-bold" v-if="cardCurrentLastDigits">
                      Doação atual no cartão **** **** ****
                      {{ cardCurrentLastDigits }}
                    </div>
                    <div
                      class="c-text-dark f-w-bold"
                      v-if="cardLastDigits != ''"
                    >
                      A doação foi atualizada para o cartão **** **** ****
                      {{ cardLastDigits }}
                    </div>
                    <p class="c-text-dark f-w-bold">
                      O valor mínimo desta campanha é
                      <span
                        >R${{
                          formatterHelper.formatMoney(
                            minimumPeriodDonationValue,
                            0
                          )
                        }}</span
                      >
                    </p>
                  </div>
                  <InputMoney
                    v-model="cartItem.itemValue"
                    :precision="0"
                    suffix=",⁰⁰"
                    ref="toInput"
                    :pattern="'\\d*'"
                    invalidInputMessage="Preencha o valor da doação"
                  />
                </div>

                <div>
                  <!-- <div class="ml-2">
                    <span class="color-theme-texto">Taxa do cartão de crédito:</span>
                    <span
                      class="color-theme-texto"
                      style="float: right; font-weight: bold"
                      >{{cart.taxValue | FormatMoney}}
                    </span>
                  </div> -->
                  <div class="ml-2">
                    <span class="color-theme-texto">Valor total:</span>
                    <span
                      class="color-theme-texto notranslate"
                      style="float: right; font-weight: bold"
                    >
                      {{ sumTotalCartValue | FormatMoney }}
                    </span>
                  </div>
                </div>

                <v-switch
                  v-model="addNewCard"
                  inset
                  :label="'Adicionar Novo Cartão de Crédito'"
                ></v-switch>

                <div v-if="!addNewCard">
                  <h2 class="fw-400 color-theme-texto">Selecione o cartão</h2>
                  <table class="v-datatable v-table theme--light">
                    <thead>
                      <tr>
                        <th class="text-sm-center" width="40">#</th>
                        <th class="text-sm-left">Número</th>
                        <th class="text-sm-left">Nome</th>
                        <th class="text-sm-left">Bandeira</th>
                        <th class="text-sm-center" width="40">#</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="item in creditCards"
                        :key="item.id"
                        :style="
                          item.id === selectedCardId
                            ? 'background: rgba(104, 187, 108, 0.1)'
                            : ''
                        "
                        @click="selectNewCreditCard(item.id)"
                      >
                        <td>
                          <a>
                            <v-icon
                              v-if="item.id === selectedCardId"
                              color="green lighten-1"
                              >radio_button_checked</v-icon
                            >
                            <v-icon v-else color="gray lighten-1"
                              >radio_button_unchecked</v-icon
                            >
                          </a>
                        </td>
                        <td>**** **** **** {{ item.last_four_digits }}</td>
                        <td>{{ item.holder_name }}</td>
                        <td>{{ item.brand }}</td>
                        <td>
                          <!-- <v-btn
                            icon
                            small
                            color="red"
                            class="mr-3"
                            @click="deleteCreditCard(item.id)"
                          >
                            <v-icon color="white"
                              >trash</v-icon
                            >
                          </v-btn> -->
                          <v-btn
                            small
                            flat
                            fab
                            @click="deleteCreditCard(item.id)"
                          >
                            <Icon
                              name="fal fa-trash"
                              size="20"
                              colorFont="#cfd2dd"
                            />
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div v-if="addNewCard">
                  <h2 class="fw-400 color-theme-texto">
                    Adicionar Novo Cartão.
                  </h2>
                  <v-layout row wrap>
                    <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                      <InputText
                        ref="cardNumberInput"
                        type="text"
                        textLabel="Número do cartão"
                        v-model="creditCard.cardNumber"
                        :disabled="!addNewCard"
                        invalidInputMessage="Preencha o número do cartão"
                        mask="#### #### #### ####"
                      />
                    </v-flex>

                    <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                      <InputText
                        ref="cardHolderNameInput"
                        type="text"
                        textLabel="Nome no cartão"
                        v-model="creditCard.cardName"
                        :disabled="!addNewCard"
                        invalidInputMessage="Preencha o nome"
                        @input="nameToUpperCase()"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                      <InputText
                        ref="expirationDateInput"
                        type="text"
                        textLabel="Data de vencimento"
                        v-model="creditCard.expirationDate"
                        :disabled="!addNewCard"
                        invalidInputMessage="Preencha a data de vencimento"
                        mask="##/####"
                        placeHolder="MM/AAAA"
                      />
                    </v-flex>
                    <v-flex xs12 sm12 md6 lg5 pr-3 p-950-r-0>
                      <InputText
                        ref="cardCVVInput"
                        type="text"
                        textLabel="CVV"
                        v-model="creditCard.CVV"
                        :disabled="!addNewCard"
                        invalidInputMessage="Preencha o código de segurança"
                        mask="###"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-switch
                      v-model="saveCreditCard"
                      inset
                      :label="'Salvar novo cartão?'"
                    ></v-switch>
                  </v-layout>
                </div>
                <v-flex text-xs-center>
                  <v-btn
                    flat
                    round
                    color="white"
                    class="btn-primary px-5 text-uppercase"
                    large
                    @click="save"
                    >Salvar
                  </v-btn>
                </v-flex>
              </div>
            </v-flex>
          </v-layout>
        </div>
      </v-container>
    </Modal>
  </div>
</template>

<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import Modal from "@/components/Modal.vue";
import CartService from "@/scripts/services/cart.service";
import PagarMeService from '@/scripts/services/pagarMe.service.js'
import CreditCard from "@/scripts/models/creditCard.model";
import { ProjectPhasesText } from "@/scripts/models/enums/projectPhases.enum";
import { ProjectTypes } from "@/scripts/models/enums/projectTypes.enum";
import CampaignService from "@/scripts/services/campaign.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import PaymentMethodProvider from "@/scripts/models/enums/paymentMethodProvider.enum";

//VUEX
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      cartService: new CartService(),
      creditCard: new CreditCard(),
      PagarMeService: new PagarMeService(),
      formatterHelper: new FormatterHelper(),
      campaignService: new CampaignService(),
      PaymentMethodProvider: PaymentMethodProvider,
      show: false,
      selectFunctional: false,
      selectStatical: false,
      selectMarketing: false,
      selectNecessary: true,
      projectTypes: null,
      configManager: null,
      addNewCard: false,
      saveCreditCard: false,
      value: 0,
      cart: {
        cartItems: [],
      },
      creditCards: [],
      selectedCardId: 0,
      CommonHelper: CommonHelper,
      minimumDonationValue: 0,
      totalCartValue: 0,
      cardLastDigits: 0,
      cardCurrentLastDigits: 0,
    };
  },
  created() {
    this.setLandingPageConfig();
    this.projectTypes = ProjectTypes;
  },
  methods: {
    ...mapActions([
      "setLandingPageConfig"
    ]),
    open(cart) {
      this.show = true;
      this.cart = cart;
      this.value = this.cart.totalValue;
      this.selectedCardId = 0;
      this.getCardLastDigits();
      this.campaignService
        .findById((data) => {
          this.cart.campaign = data
        }, this.cart.campaignId)
        //.then((data) => (this.cart.campaign = data.campaign));
    },
    getCreditCards() {
      this.cartService.listCustomerCards(this.cart.userId, this.paymentMethodProviders.creditCard).then(
        function (res) {
          this.creditCards = res.data;

          if (this.creditCards.length === 0) {
            this.addNewCard = true;
          } else {
            this.selectedCardId = this.creditCards.find(x => x.active).id || this.creditCards[0].id;
          }
        }.bind(this)
      );
    },
    getProjectTypes() {
      this.this.projectTypes = ProjectTypes;
    },
    selectNewCreditCard(cardId) {
      if (this.selectedCardId == cardId) this.selectedCardId = 0;
      else this.selectedCardId = cardId;
    },
    deleteCreditCard(creditCardId) {
      this.cartService.deleteCreditCard(creditCardId).then(() => {
        this.getCreditCards();
        this.CommonHelper.swal("Cartão excluido", null, "success");
      });
    },
    nameToUpperCase() {
      this.creditCard.cardName = this.creditCard.cardName.toUpperCase();
    },
    async save() {
      let cartItems = [];
      let value = 0;
      for (const cartItem of this.cart.cartItems) {
        cartItems.push({ cartItemId: cartItem.id, value: cartItem.itemValue });
        value += cartItem.itemValue;

        if (cartItem.itemValue < this.minimumDonationValue) {
          this.CommonHelper.swal(
            "Desculpe, você está doando um valor inferior ao mínimo permitido nessa campanha"
          );
          return;
        }
      }

      let item = {
        value: this.totalCartValue,
        cartId: this.cart.id,
        cardId: this.selectedCardId,
        subscriptionId: this.cart.subscriptionId,
        asaasSubscriptionId: this.cart.asaasSubscriptionId,
        cartItems: cartItems,
        creditCard: {
          ...this.creditCard,
          saveCreditCard: this.saveCreditCard,
        },
        addNewCard: this.addNewCard,
        tax: this.cart.taxValue,
      }
      item.creditCard = {
          ...this.creditCard,
          saveCreditCard: this.saveCreditCard,
        }

      this.cartService
        .changeSignature(item)
        .then(async () => {
            
          if (this.saveCreditCard === false &&
          this.addNewCard &&
            this.paymentMethodProviders.creditCard === this.PaymentMethodProvider.Asaas) {
              await this.deleteAsaasLastCard(item)
          }

          this.getCreditCards();
          if (this.addNewCard === true && this.saveCreditCard == true) {
            this.show = true;
            this.addNewCard = false;
          } else {
            document.location.reload();
            this.show = false;
          }
          this.creditCard = new CreditCard();
          this.addNewCard = false;
          this.saveCreditCard = false;
        })
        .then(() => {
          this.CommonHelper.swal("Assinatura atualizada", null, "success");
        });
    },
    async tokenizerCard() {
      if (this.paymentMethodProviders.creditCard === this.PaymentMethodProvider.PagarMe) {
        const response = await this.pagarMeService.tokenizerCard({
          type: 'card',
          card: this.card
        })
        return response.data.id;
      } else if (this.paymentMethodProviders.creditCard === this.PaymentMethodProvider.Asaas) {
        const response = await this.cartService.createCreditCardOnGateway({
          userId: this.profile.userId,
          cardNumber: this.creditCard.cardNumber,
          expirationDate: this.creditCard.expirationDate,
          cardName: this.creditCard.cardName,
          cvv: this.creditCard.CVV
        })
        return response
      }
      return null
    },
    async deleteAsaasLastCard(item) {
      const cardToken = item.creditCard.cardToken;
      await this.cartService.deleteByCardId(cardToken)
    },
    getInstitutionName(cartItem) {
      if (
        cartItem.project != null &&
        cartItem.project.institution != null &&
        cartItem.project.institution.entity != null
      )
        return cartItem.project.institution.entity.name;
      return "";
    },
    getCardLastDigits() {
      const subscriptionId = this.cart.subscriptionId;

      this.cartService
        .getCreditCardsLastDigits(subscriptionId)
        .then((data) => {
          if (data && data.length > 4) {
            this.cardLastDigits = data.substring(0, 4);
            this.cardCurrentLastDigits = data.substring(4);
          } else {
            this.cardLastDigits = "";
            this.cardCurrentLastDigits = data;
          }

          this.getCreditCards();
        });
    },
    cartTaxMethodPayment(value) {
      let totalPrice = value;
      let taxRecurrentCreditCard = 3.5; //TODO: Use configManager this.configManager.taxRecurrentCreditCard
      let tax = 0;

      if (totalPrice == 0) {
        return 0;
      }

      tax = totalPrice / ((100 - taxRecurrentCreditCard) / 100) - totalPrice;

      this.cart.taxValue = 0;

      return 0;
    },
  },
  watch: {
    show: function (params) {
      if (!params) {
        this.$router.replace({ query: null });
        this.$router.go(0);
      }
    },
  },
  computed: {
    ...mapGetters([
      "campaignLandingPageConfig",
      "profile",
      "paymentMethodProviders"
    ]),
    minimumPeriodDonationValue() {
      let minimumDonationValue = 0;

      let recurrencePeriod = parseInt(this.cart.donationRecurrencePeriodId);

      if (this.cart.campaign.campaignDonationRecurrencePeriodList && this.cart.campaign.campaignDonationRecurrencePeriodList.length == 0) {
        return 0;
      }

      let campaignDonationRecurrencePeriodList =
        this.cart.campaign.campaignDonationRecurrencePeriodList.find(
          (cdrp) => cdrp.donationRecurrencePeriodId == recurrencePeriod
        );

      minimumDonationValue =
        campaignDonationRecurrencePeriodList.minimumDonationValue > 0
          ? campaignDonationRecurrencePeriodList.minimumDonationValue
          : this.cart.campaign.minimumDonationValue;

      this.cart.campaign.minimumDonationValue = minimumDonationValue;

      this.minimumDonationValue = minimumDonationValue;

      return minimumDonationValue;
    },

    sumTotalCartValue() {
      this.totalCartValue = 0;
      this.cart.cartItems.forEach((cartItem) => {
        this.totalCartValue += cartItem.itemValue;
      });

      this.cartTaxMethodPayment(this.totalCartValue);

      return this.totalCartValue;
    },
  },
};
</script>
