<template>
  <div class="box-profile">
    <div
      class="box-profile-title"
      :class="{'flag-green':entity.type == entityType.Institution, 'flag-orange': entity.type == entityType.Council,'flag-blue':entity.type == entityType.Company || entity.type == entityType.AUC}"
    >
      <div>
        <Icon
          v-if="entity.type == entityType.Company"
          name="fas fa-user-tie"
          size="16"
          class="mr-3"
          colorFont="#fff"
        />
        <Icon
          v-else-if="entity.type == entityType.AUC"
          name="fas fa-heart-square"
          size="16"
          class="mr-3"
          colorFont="#fff"
        />
        <Icon
          v-else-if="entity.type == entityType.Institution"
          name="fas fa-hands"
          size="16"
          class="mr-3"
          colorFont="#fff"
        />
        <Icon
          v-else-if="entity.type == entityType.Council"
          name="fas fa-atlas"
          size="16"
          class="mr-3"
          colorFont="#fff"
        />
      </div>
      <span v-if="entity.type == entityType.AUC">Plataforma</span>
      <span v-else>{{getEntityType(entity.type)}}</span>
    </div>
    <div
      class="box-profile-img"
      :style="'background-image:url(https://ui-avatars.com/api/?name='+entity.name+'&length=3&size=512&font-size=0.2&color=C3C6D5&background=FFFFFF)'"
    >
      <FileImage :file="file" :alt="''" />
    </div>
    <!-- <h4 v-if="entity.type == entityType.AUC">Plataforma</h4> -->
    <h4>
      <span>{{entity.name}}</span>
      <span
        class="pill violet f-right fw-900"
        v-if="hasPermission([permissions.ConciliationRH],entity.id)"
      >RH</span>
    </h4>
    <div class="box-profile-date">
      <div>{{entity.qtyOfMembers}} integrante(s)</div>
      <span>Criado em: {{formatterHelper.formatDate(entity.creationDate)}}</span>
    </div>
  </div>
</template>

<script type="plain/text">
import InstitutionService from "@/scripts/services/institution.service";
import CompanyService from "@/scripts/services/company.service";
import CouncilService from "@/scripts/services/council.service";
import FileImage from "@/components/FileImage.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";
import {
  EntityType,
  EntityTypeList
} from "@/scripts/models/enums/entityType.enum";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import { mapGetters, mapState } from "vuex";
import Permissions from "@/scripts/models/enums/permissions.enum";
export default {
  props: ["entity"],
  components: {
    FileImage
  },
  data() {
    return {
      formatterHelper: new FormatterHelper(),
      entityType: EntityType,
      file: null,
      institutionService: new InstitutionService(),
      companyService: new CompanyService(),
      councilService: new CouncilService(),
      permissions: null
    };
  },
  mounted() {
    this.getEntityFile();
  },
  created() {
    this.permissions = Permissions;
  },
  computed: {
    ...mapGetters(["hasPermission"])
  },
  methods: {
    getEntityFile() {
      if (this.entity.type == EntityType.Institution) {
        this.institutionService
          .getFileByEntity(this.entity.id)
          .then(this.getFileCallback);
      } else if (
        this.entity.type == EntityType.Company ||
        this.entity.type == EntityType.AUC
      ) {
        this.companyService
          .getFileByEntity(this.entity.id)
          .then(this.getFileCallback);
      } else if (this.entity.type == EntityType.Council) {
        this.councilService
          .getFileByEntity(this.entity.id)
          .then(this.getFileCallback);
      }
    },
    getFileCallback(data) {
      this.file = data;
    },
    getEntityType(entityType) {
      return EntityTypeList.filter(et => et.id == entityType)[0].name;
    }
  }
};
</script>